import React from 'react';
import '../assets/css/loader.css';

function Loader() {
    return (
        <div className='loader_main'>
            <div className="loader"></div>
        </div>
    )
}

export default Loader;