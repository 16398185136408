import React, { lazy, Suspense } from 'react';

// import React router dom packages
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import Loader from '../Components/Loader';

// import Pages
const Home = lazy(() => import('../Pages/Home'));
const Hydration = lazy(() => import('../Pages/Hydration'));
const AboutUs = lazy(() => import('../Pages/AboutUs'));
const ContactUs = lazy(() => import('../Pages/ContactUs'));
const NotFound = lazy(() => import('../Pages/NotFound'));

function AppRouter() {
    return (
        <Suspense fallback={<Loader />}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/hydration" element={<Hydration />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </Suspense>
    )
}

export default AppRouter;