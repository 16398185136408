import React from 'react';
// import Router File
import AppRouter from './Config/AppRouter';
// import Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// import Main css File
import './App.css';
import './assets/css/media-query.css'

function App() {
  return (
    <AppRouter />
  )
}

export default App;